import React, { useState, useEffect } from "react";
import { maskCpfCnpj } from "../../core/utils/format";
import { Button } from "../../core/components/button";
import { InventoryPage } from "../riskanalysis/InventoryPage";
import { useRefresh } from "../../core/hooks/refresh";
import { CheckListPage } from "../riskanalysis/CheckListPage";
import { userType } from "../user/utils";

import { parseNumber } from "../../core/utils/parse";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import {
  getList,
  getById,
  get,
  downloadReport,
  post,
  downloadRiskReport,
} from "../../service";
import { forceDownload, classNames } from "../../core/helpers/misc";

export const UserReportsList = ({ global, route }) => {
  const [customerId] = useState(route.match.params["id"]);
  const [fetching, setFetching] = useState(false);
  const [user, setUser] = useState({ name: "", cpfCnpj: "", customerCode: "" });
  const [userAnalitic, setUserAnalitc] = useState({
    clientRiskValue: 0,

    sumaryPoints: {
      totAceitavel: 0,
      totMuitoBaixo: 0,
      totBaixo: 0,
      totSignificante: 0,
      totAlto: 0,
      totMuitoAlto: 0,
      totExtremo: 0,
      totInaceitavel: 0,
      sumaryPoints: 0,
      listAnalisys: [
        {
          idAnalisy: 0,
          aceitavel: 0,
          muitoBaixo: 0,
          baixo: 0,
          significante: 0,
          alto: 0,
          muitoAlto: 0,
          extremo: 0,
          inaceitavel: 0,
          total: 0,
          name: "",
          tag: "",
          sector: "",
        },
      ],
    },
  });
  const [validClient, setValidClient] = useState(true);

  const [logedUserType] = useState(global.operator.type);
  const refresh = useRefresh();
  const [downloading, setDownloading] = useState(false);
  const [activePage, setActivePage] = useState("checklist");

  useEffect(() => {
    get(`UserDocs.ValidCustumer?id=${customerId}`)
      .then((valid) => {
        if (!valid) {
          setValidClient(false);
          global.modal.alert(
            "Cliente não encontrado ou acesso não permitido.",
            () => route.history.push("/")
          );
        } else {
          return getById("user", parseNumber(customerId));
        }
      })
      .then((user) => {
        setUser(user);
      })
      .catch((err) => global.modal.alert(err.message));
  }, []);

  useEffect(() => {
    if (!global.loading && validClient) {
      setFetching(true);

      get(`Analitics.clientRisk?id=${customerId}`)
        .then((done) => {
          if (done) {
            setUserAnalitc(done);
          }
        })
        .catch((err) => {
          global.modal.alert(err.message);
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }, [validClient, global.loading, refresh.ref]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-3">
          <div className="card my-profile">
            <div className="card-body p-2">
              <ul className="nav flex-column">
                <p className="menu-label pl-1">Todos os relatórios</p>
                <li className="nav-item mb-1">
                  <a
                    className={
                      activePage == "checklist" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      setActivePage("checklist");
                    }}
                  >
                    <i className="fas fa-check fa-lg fa-fw mr-3"></i> Checklist
                  </a>
                </li>
                <li className="nav-item mb-1">
                  <a
                    className={
                      activePage == "matriz" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      setActivePage("matriz");
                    }}
                  >
                    <i className="fas fa-th-list fa-lg fa-fw mr-3"></i> Matriz
                    de riscos
                  </a>
                </li>

                <li className="nav-item mb-1">
                  <a
                    className={
                      activePage == "inventario"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => {
                      setActivePage("inventario");
                    }}
                  >
                    <i className="fas fa-boxes fa-lg fa-fw mr-3"></i> Inventário
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9 ptm-3">
          {activePage == "matriz" ? (
            <div className="card">
              <div className="card-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-auto mr-auto pl-0">
                      <div className="ptm-3 display-inline-table font-medium-5">
                        <h5>
                          <i className="fas fa-th-list mr-1"></i>Matriz de
                          riscos
                        </h5>
                      </div>
                    </div>

                    <div className="col-md-auto"></div>

                    <div className="col-auto no-padding">
                      {logedUserType !== userType.ADMIN ? (
                        <Button
                          customClassName="btn-secondary mtm-1 mb-0"
                          icon="fas fa-angle-left valign-middle"
                          title="Voltar"
                          onClick={() => route.history.goBack()}
                        />
                      ) : null}
                      {logedUserType !== userType.ADMIN ? (
                        <Button
                          disabled={
                            !userAnalitic?.sumaryPoints?.listAnalisys.length
                          }
                          customClassName="btn-secondary btn-pdf mtm-1 mb-0 ml-1"
                          icon="fas fa-file-pdf valign-middle"
                          title="Baixar matriz de risco"
                          loading={downloading}
                          onClick={() => {
                            setDownloading(true);
                            downloadRiskReport(
                              customerId,
                              `${user.customerCode}-MATRIZ-DE-RISCOS.pdf`
                            )
                              .then((blob) =>
                                forceDownload(
                                  blob,
                                  `${user.customerCode}-MATRIZ-DE-RISCOS.pdf`,
                                  "Matriz de risco"
                                )
                              )
                              .catch((err) => global.modal.alert(err.message))
                              .finally(() => setDownloading(false));
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {fetching ? (
                    <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                      <p>
                        <Skeleton height={60} count={1} />
                      </p>
                    </SkeletonTheme>
                  ) : (
                    <div id="pdf">
                      <div
                        className="alert alert-dark p-1 text-center"
                        role="alert"
                      >
                        <p className="rank-caption">
                          LEGENDA/COR DOS PONTOS:{" "}
                          <span className="aceitavel">■ ACEITÁVEL</span>{" "}
                          <span className="muitoBaixo">■ MUITO BAIXO</span>{" "}
                          <span className="baixo">■ BAIXO</span>{" "}
                          <span className="significante">■ SIGNIFICANTE</span>{" "}
                          <span className="alto">■ ALTO</span>{" "}
                          <span className="muitoAlto">■ MUITO ALTO</span>{" "}
                          <span className="extremo">■ EXTREMO</span>{" "}
                          <span className="inaceitavel">■ INACEITÁVEL</span>
                        </p>
                      </div>
                      <table className="table table-hover table-sm">
                        <thead className="thead-dark">
                          <tr>
                            <th className="text-center" colSpan="4">
                              Dados dos equipamentos
                            </th>
                            <th className="text-center" colSpan="9">
                              Nível de riscos
                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th>Equipamento/ID</th>
                            <th>Tag</th>
                            <th>Descrição</th>
                            <th>Setor</th>
                            <th className="display-9 aceitavel"></th>
                            <th className="display-9 muitoBaixo"></th>
                            <th className="display-9 baixo"></th>
                            <th className="display-9 significante"></th>
                            <th className="display-9 alto"></th>
                            <th className="display-9 muitoAlto"></th>
                            <th className="display-9 extremo"></th>
                            <th className="display-9 inaceitavel"></th>
                            <th className="display-9">Nº Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userAnalitic.sumaryPoints.listAnalisys.map((a) => {
                            const sequential = `${a.idAnalisy}`.padStart(
                              7,
                              "0"
                            );
                            return (
                              <tr key={a.idAnalisy}>
                                <th scope="row">
                                  {user.customerCode}-{sequential}
                                </th>
                                <td>
                                  <div className="cut-text-mini">{a.tag}</div>
                                </td>
                                <td>
                                  <div className="cut-text-mini">{a.name}</div>
                                </td>
                                <td>
                                  <div className="cut-text-mini">
                                    {a.sector}
                                  </div>
                                </td>
                                <td className="aceitavel">{a.aceitavel}</td>
                                <td className="muitoBaixo">{a.muitoBaixo}</td>
                                <td className="baixo">{a.baixo}</td>
                                <td className="significante">
                                  {a.significante}
                                </td>
                                <td className="alto">{a.alto}</td>
                                <td className="muitoAlto">{a.muitoAlto}</td>
                                <td className="extremo">{a.extremo}</td>
                                <td className="inaceitavel">{a.inaceitavel}</td>
                                <td>{a.total}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot className="tfoot-dark">
                          <tr>
                            <td colSpan="4" className="text-center">
                              Somatoria de pontos
                            </td>
                            <td> {userAnalitic.sumaryPoints.totAceitavel}</td>
                            <td> {userAnalitic.sumaryPoints.totMuitoBaixo}</td>
                            <td> {userAnalitic.sumaryPoints.totBaixo}</td>
                            <td>
                              {" "}
                              {userAnalitic.sumaryPoints.totSignificante}
                            </td>
                            <td> {userAnalitic.sumaryPoints.totAlto}</td>
                            <td> {userAnalitic.sumaryPoints.totMuitoAlto}</td>
                            <td> {userAnalitic.sumaryPoints.totExtremo}</td>
                            <td> {userAnalitic.sumaryPoints.totInaceitavel}</td>
                            <td> {userAnalitic.sumaryPoints.sumaryPoints}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer">
                <small>
                  <i className="fas fa-question fa-fw fa-sm"></i> Listagem de
                  todas as análises criadas para "{`${user.name}`}" e os níveis
                  de riscos encontrados nos pontos.
                </small>
              </div>
            </div>
          ) : null}

          {activePage == "inventario" ? (
            <InventoryPage
              route={route}
              global={global}
              customerId={customerId}
            ></InventoryPage>
          ) : null}

          {activePage == "checklist" ? (
            <CheckListPage
              route={route}
              global={global}
              customerId={customerId}
            ></CheckListPage>
          ) : null}
        </div>
      </div>
    </>
  );
};
