import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button } from "../../core/components/button";
import { parseNumber } from "../../core/utils/parse";
import { downloadCheckList, getById, get } from "../../service";
import InventoryNoContent from "../../assets/img/027-error.png";
import { forceDownload } from "../../core/helpers/misc";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement
);

import { Pie } from "react-chartjs-2";

const CheckListSkeleton = ({ fetching, checkList, customer }) => {
  if (fetching) {
    return (
      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
        <p>
          <Skeleton duration={1.5} height={50} count={1} />
        </p>
      </SkeletonTheme>
    );
  }

  if (checkList.length === 0) {
    return (
      <div className="info-no-content">
        <img
          className="image"
          alt="Não há Analises para checkList"
          src={InventoryNoContent}
        />
        <div className="inventory">
          Ainda não existem equipamentos analisados para compor o checkList.
        </div>
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <tbody>
          {checkList.map((m) => {
            const sequential = `${m.analisy}`.padStart(7, "0");
            return (
              <tr key={m.analisy}>
                <th>
                  <h6 className="list-title">ID da análise</h6>
                  {customer.customerCode}-{sequential}
                </th>
                <th>
                  <h6 className="list-title">TAG</h6>
                  {m.tag}
                </th>

                <th>
                  <h6 className="list-title">Nome da máquina</h6>
                  {m.name}
                </th>
                <th>
                  <h6 className="list-title">Setor localizado</h6>
                  {m.sector}
                </th>
                <th>
                  <h6 className="list-title">Tipo</h6>
                  {m.type}
                </th>
                <th>
                  <h6 className="list-title">Marca</h6>
                  {m.brand}
                </th>
                <th>
                  <h6 className="list-title">Modelo</h6>
                  {m.model}
                </th>
                <th>
                  <h6 className="list-title">Número de Série</h6>
                  {m.serialNumber}
                </th>
                <th>
                  <h6 className="list-title">Apreciação de Riscos</h6>
                  <div className={getClassColor(m.analisyies)}>
                    {m.analisyies}
                  </div>
                </th>
                <th>
                  <h6 className="list-title">Estudo de Adequação</h6>
                  <div className={getClassColor(m.studies)}>{m.studies}</div>
                </th>
                <th>
                  <h6 className="list-title">Laudo de Adequação</h6>
                  <div className={getClassColor(m.tecnicalReport)}>
                    {m.tecnicalReport}
                  </div>
                </th>
                <th>
                  <h6 className="list-title">Procedimento de Trabalho</h6>
                  <div className={getClassColor(m.proceduresReport)}>
                    {m.proceduresReport}
                  </div>
                </th>
                <th>
                  <h6 className="list-title">Manual de Instruções</h6>
                  <div className={getClassColor(m.manualReport)}>
                    {m.manualReport}
                  </div>
                </th>
                <th>
                  <h6 className="list-title">Capacitação</h6>
                  <div className={getClassColor(m.trainingReport)}>
                    {m.trainingReport}
                  </div>
                </th>
                <th>
                  <h6 className="list-title">Laudo de Aterramento</h6>
                  <div className={getClassColor(m.groundingReport)}>
                    {m.groundingReport}
                  </div>
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const getClassColor = (status) => {
  switch (status) {
    case "Sim":
      return "aceitavel";
    case "Não":
      return "muitoAlto";
  }
};

export const CheckListPage = ({ route, global, customerId }) => {
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkList, setCheckList] = useState([]);
  const [customer, setCustomer] = useState({});
  const [viewMode, setViewMode] = useState("table");

  useEffect(() => {
    const key = route.match.params.id || customerId;
    setLoading(true);
    Promise.all([
      getById("user", parseNumber(key), null),
      get(`Analitics.checkListView?id=${key}`),
    ])
      .then(([customer, checkList]) => {
        setCustomer(customer);
        setCheckList(checkList);
      })
      .catch((err) => global.modal.alert(err.message))
      .finally(() => setLoading(false));
  }, []);

  const fields = [
    { key: "analisyies", title: "Análise de Riscos" },
    { key: "studies", title: "Estudo de Adequação" },
    { key: "tecnicalReport", title: "Laudo de Adequação" },
    { key: "groundingReport", title: "Laudo de Aterramento" },
    { key: "trainingReport", title: "Capacitação" },
    { key: "proceduresReport", title: "Procedimento de Trabalho" },
    { key: "manualReport", title: "Manual de Instruções" },
  ];

  const generateChartData = (sim, nao) => {
    const total = sim + nao;
    const formatPercent = (value) =>
      total > 0 ? parseFloat(((value / total) * 100).toFixed(2)) : 0;

    return {
      labels: [`Sim - ${formatPercent(sim)}%`, `Não - ${formatPercent(nao)}%`],
      datasets: [
        {
          data: [sim, nao],
          backgroundColor: ["#7DDE92", "#FF6B6B"],
          hoverBackgroundColor: ["#5FCC77", "#FF5252"],
          borderColor: "#FFFFFF",
          borderWidth: 2,
          hoverBorderWidth: 3,
          cutout: "50%",
        },
      ],
    };
  };

  const ChartsGrid = ({ checkList }) => {
    const [chartsData, setChartsData] = useState([]);
    const chartOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#FFFFFF", // Ajuste para a cor desejada
            font: {
              size: 14, // Opcional: definir tamanho da fonte
            },
          },
        },
      },
    };

    useEffect(() => {
      if (!checkList || checkList.length === 0) return;

      const calculatedData = fields.map((field) => {
        const simCount = checkList.filter(
          (item) => item[field.key] === "Sim"
        ).length;
        const naoCount = checkList.filter(
          (item) => item[field.key] === "Não"
        ).length;

        return {
          title: field.title,
          sim: simCount,
          nao: naoCount,
        };
      });

      setChartsData(calculatedData);
    }, [checkList]);

    return (
      <div className="row">
        {chartsData.map((chart, index) => (
          <div key={chart.title || index} className="col-md-3 col-sm-6 mb-4">
            <div
              className="card text-center p-3 shadow-sm"
              style={{
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h6 className="mb-3">{chart.title}</h6>
              <div style={{ width: "100%", margin: "0 auto" }}>
                <Pie
                  data={generateChartData(chart.sim, chart.nao)}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-md">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5>
              <i className="fas fa-check mr-1"></i>Lista de Documentos
              (Checklist)
            </h5>
            <div className="d-flex gap-2 ms-auto">
              <Button
                customClassName="btn btn-outline-secondary mtm-2 mb-0 mt-0 ml-1"
                title="Alternar visualização"
                icon={viewMode === "table" ? "fas fa-chart-bar" : "fas fa-list"}
                onClick={() =>
                  setViewMode(viewMode === "table" ? "chart" : "table")
                }
              />
              <Button
                customClassName="btn btn-secondary mtm-2 mb-0 mt-0 ml-1"
                title="Voltar"
                icon="fas fa-angle-left valign-middle"
                onClick={() => route.history.goBack()}
              />
              <Button
                title="Baixar checkList"
                id="checkListPDF"
                icon="fas fa-file-pdf valign-middle"
                customClassName="btn btn-primary btn-pdf mtm-2 mb-0 mt-0 ml-1"
                disabled={downloading || !checkList.length}
                loading={downloading}
                onClick={() => {
                  setDownloading(true);
                  downloadCheckList(
                    customer.id,
                    `${customer.customerCode}-LISTA-DOCUMENTOS.pdf`
                  )
                    .then((blob) =>
                      forceDownload(
                        blob,
                        `${customer.customerCode}-LISTA-DOCUMENTOS.pdf`,
                        "LISTA DE DOCUMENTOS"
                      )
                    )
                    .catch((err) => global.modal.alert(err.message))
                    .finally(() => setDownloading(false));
                }}
              />
            </div>
          </div>
          <div className="card-body">
            {viewMode === "table" ? (
              <CheckListSkeleton
                fetching={loading}
                checkList={checkList}
                customer={customer}
              />
            ) : (
              <div className="chart-placeholder">
                <ChartsGrid checkList={checkList} />
              </div>
            )}
          </div>
          <div className="card-footer">
            <small>
              <i className="fas fa-question fa-fw fa-sm"></i> CheckList de
              analises cadastrado para o cliente "
              <span className="data cut">{customer.name}</span>".
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};
